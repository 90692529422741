@import '~ht-styleguide/build/grid_classes';
@import '~ht-styleguide/build/spacing';

.phoneIcon {
  font-size: 18px;

  @include with-lg-grid {
    @include spacing(margin, 0.5 0.5 0 0);

    font-size: 14px;
  }
}
