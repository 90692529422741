@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/spacing';

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
};

.htLogo {
  height: 10px;
  @media (min-width:350px) {
    @include spacing(height, 1.5); // ~13px
  }
  @include with-md-grid {
    @include spacing(height, 2.5); // ~20px
  }
  @include with-lg-grid {
    @include spacing(height, 4); // ~30px
  }
}

.logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;

  @media (min-width:350px) {
    @include spacing(height, 3); // ~20px
  }
  @include with-md-grid {
    min-width: 100px;
    @include spacing(height, 3); // ~35px
  }

  img {
    height: 100%;
    // margin-top: 2px;
  }
}

.cobranded {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  div:nth-of-type(2) {
    @include spacing(margin-left, 2);
  }
}

.default {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;

  div:nth-of-type(2) {
   // @include spacing(margin-left, 2);
  }
}

.verticalLine {
  border-left: 1px solid $ht-navy-300;
  height: 30px;
  margin: 0 10px;
}
