@import 'src/styles/variables';

.topNavItemCount {
  position: absolute;
  top: 0;
  right: -2px;
  background-color: $ht-blue-700;
  border-radius: 2px;
  font-size: 0.3em;
  font-weight: $ht-font-weight-medium;
  color: $ht-white;

  height: 13px;
  width: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}
