@import 'src/styles/variables';
@import 'src/styles/mixins';
@import '~ht-styleguide/build/variables';

.footerWrapper {
  min-height: 100px;
}

.mainWrapper {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.hideFooter {
    .footerWrapper {
      height: 0;
    }
  }

  &.bgNavy050Mobile {
    background-color: $ht-navy-050;
    @include with-desktop {
      background-color: inherit;
    }
  }

  &.bgNavy050 {
    @include with-desktop {
      background-color: $ht-navy-050;
    }
  }

  .mainContentWrapper {
    position: relative;
    flex: 1;
    padding-top: var(--desktop-spacing-promotional-bar);
    min-height: 85vh;
  }
}

.buttonFooterOffset {
  &.show {
    height: $ht-button-footer-mobile-height;
    @media (min-width: $ht-tablet-width) {
      height: 0;
    }
  }

  &.tablet {
    @media (min-width: $ht-tablet-width) {
      height: $ht-button-footer-tablet-height;
    }
    @media (min-width: $ht-desktop-width) {
      height: 0;
    }
  }

  &.desktop {
    @media (min-width: $ht-desktop-width) {
      height: $ht-button-footer-desktop-height;
    }
  }
}
