@import '~ht-styleguide/build/grid_classes';
@import 'src/styles/variables';

.container {
  display: flex;
  justify-content: space-between;
  flex: 1;

  .rightContent {
    display: flex;
    align-items: center;
  }

  .phoneLink {
    position: relative;
    color: $ht-navy-700;

    &:hover {
      color: inherit;
    }
  }

  .phoneNumber {
    display: none;

    @include with-lg-grid {
      display: inline;
    }
  }
}

