@import 'src/styles/variables';
@import '~ht-styleguide/build/grid_classes';

.topNavWrapper {
  position: sticky;
  z-index: $ht-z-index-header;
  top: 0;
  width: 100%;
  border-bottom: $ht-divider-200;
  box-shadow: $ht-box-shadow-header-bottom;
}
