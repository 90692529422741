@import '~ht-styleguide/build/variables';
@import '~ht-styleguide/build/mixins';
@import '~ht-styleguide/build/grid_classes';
@import '~ht-styleguide/build/spacing';
@import '~ht-styleguide/build/typography_placeholders';
@import 'src/styles/mixins';

.navContainer {
  background-color: $ht-navy-000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include spacing(margin, 0 auto);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 7;
  margin-left: 0;

  @include with-md-grid {
    @include spacing(padding, 2 3);

    width: 100%;
    left: 0;
  }

  @include with-lg-grid {
    @include spacing(padding, 3 4);
  }
}

.container {
  background-color: $ht-navy-000;
  transition: background-color 150ms linear;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  height: $ht-topnav-mobile-height;

  @include spacing(padding, 2);
  @include with-md-grid {
    height: $ht-topnav-tablet-height;
    @include spacing(padding, 2 3);
  }

  @include with-lg-grid {
    height: $ht-topnav-desktop-height;
    @include spacing(padding, 3 4);
  }

  &.transparent {
    background-color: transparent;
    border-bottom: none;
    box-shadow: none;
  }
}

.searchOverlay {
  background-color: rgba($ht-navy-900, 0.72);
  position: absolute;
  top: $ht-topnav-mobile-height;
  min-height: 100vh;
  min-width: 100vw;
  z-index: -1;

  @include with-lg-grid {
    top: $ht-topnav-desktop-height;
  }
}

.searchContainer {
  @include spacing(padding, 0 !important);

  width: 100%;

  @include with-lg-grid {
    position: relative;
  }
}

.switchSearchIcon {
  display: flex;
  flex-direction: row-reverse;
  & > * {
    @include spacing(margin-left, 2.5);
  }

  @include with-md-grid {
    flex-direction: row;
  }
}

.leftContent,
.centerContent {
  flex: 1;
}

.rightContent {
  display: flex;
  justify-content: flex-end;
  flex: 0;
  @include with-lg-grid {
    flex: 1;
  }

  & > * {
    &:not(:last-child) {
      @include with-lg-grid {
        @include spacing(margin-right, 2.5);
      }
    }
  }
}

.rightContentSearch {
  flex: 1;
  height: 104%;
  width: 100%;

  @include with-lg-grid {
    height: auto;
  }
}

.centerContent,
.closeIcon,
.hamburgerIcon,
.htLogo,
.profileIcon {
  cursor: pointer;
}

.leftContent {
  display: flex;
  align-items: center;
}

.leftContentLinkWrapper {
  flex: 0;
  display: flex;
  align-items: center;
}

.closeIcon,
.hamburgerIcon {
  color: $ht-navy-700;
  position: relative;
  top: 2px;
  @include with-lg-grid {
    display: none;
  }
}

.closeIcon {
  font-size: 16px;
  margin-right: 20px;
}

.hamburgerIcon {
  font-size: 20px;
  margin-right: 16px;
}

.htLogo {
  font-size: 42px;
  color: $ht-navy-900;
}

.partnerLogo {
  $partner-logo-horizontal-spacing: 14px;
  flex: 1;
  align-self: stretch;
  background-position: center left;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: $partner-logo-horizontal-spacing;
  border-left: 1px solid $ht-navy-900;
  margin: 7px $partner-logo-horizontal-spacing;
}

.profileIcon {
  display: none;

  @include with-md-grid {
    display: block;
    color: $ht-navy-700;
    font-size: 25px;
    @include spacing(margin-left, 2.5);

    &:hover {
      color: $ht-navy-900;
    }
  }
  @include with-lg-grid {
    @include spacing(margin-left, 0);
  }
}

.phoneIcon {
  @include spacing(margin-right, 0.5);

  font-size: 14px;
  position: relative;
  top: 4px;
}

.clickToCallWrapper {
  display: none;
  @include with-lg-grid {
    display: block;
  }
}

.clickToCall {
  display: flex;
  white-space: nowrap;
  color: $ht-navy-900 !important;
  &:hover {
    text-decoration: none !important;
  }
}

.cartIcon {
  font-size: 25px;
}

.chatIcon {
  @include with-lg-grid {
    display: none;
  }
}

.chevron {
  font-size: 12px;
  display: inline-block;
  @include spacing(padding, 0 0 0 1);
  &.chevronUp {
    @include spacing(padding, 0 1 0 0);

    transform: rotate(180deg);
    color: $ht-blue-700;
  }
}

.centerContent {
  display: none;
  @include with-lg-grid {
    display: flex;
    justify-content: center;
    white-space: nowrap;
  }
}

.base,
.faq,
.memberships {
  @include spacing(padding, 0 2);
  &:hover {
    color: $ht-blue-700 !important;
  }
}

.phone {
  @extend %caption;
  @include spacing(padding, 1);

  background-color: $ht-navy-000;
  text-align: center;
  top: 60px;
  position: absolute;
  right: 130px;
  width: 175px;
  z-index: 3;
  box-shadow: $ht-box-shadow-all-sides;
}

.accountMenu {
  width: 92%;
  background-color: $ht-navy-000;
  position: absolute;
  top: $ht-topnav-mobile-height;
  box-shadow: $ht-box-shadow-all-sides;
  z-index: 99;
  @include spacing(margin, 0 auto);

  @include common-border-radius();

  @include with-md-grid {
    position: absolute;
    width: 240px;
    top: 72px;
    right: 32px;
  }
}

.accountMenuItem div:not([class*='Loaders']) {
  @include spacing(padding, 1.75 2 1.75 2.5);
}

.accountMenu > div:last-child {
  border-top: 1px solid $ht-navy-200;
  @include spacing(margin-bottom, 1);
}

.accountMenu > div:first-child {
  @include spacing(padding-top, 1);
}

.accountMenuItem {
  cursor: pointer;
  font-size: 16px;

  &:hover {
    color: $ht-blue-700;
    background-color: $ht-navy-100;
  }

  a {
    color: $ht-navy-900 !important;
    @include override-link-styles;
    font-weight: $ht-font-weight-regular !important;
  }
}

.servicesContainer {
  @include spacing(padding-right, 2.5);
  &:hover * {
    color: $ht-blue-700 !important;
  }
}

.servicesActive {
  color: $ht-blue-700;
}

.topNavCart {
  position: relative;
  display: block;
  color: $ht-navy-700;
  text-decoration: none;
  cursor: pointer;
  font-size: 25px;
  &:hover {
    color: $ht-navy-900;
  }
}

.topNavItemCount {
  position: absolute;
  top: 0;
  right: -2px;
  background-color: $ht-blue-700;
  border-radius: 2px;
  font-size: 0.3em;
  font-weight: $ht-font-weight-medium;
  color: $ht-white;
  @include spacing(padding, 0.5 0.5);
}

.icon {
  color: $ht-navy-700;
  font-size: 24px;
  cursor: pointer;
}
