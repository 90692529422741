@import 'variables';

@mixin text-gradient($background-gradient) {
  background: $background-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

@mixin text-overflowed() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin form-error-label() {
  position: absolute;
  top: 100%;
  right: 0;
  font-size: 13px;
  color: $ht-vibrant-red;
  font-weight: $ht-font-weight-regular;
}

@mixin common-border-radius() {
  border-radius: 2px;
}

@mixin pronounced-border-radius() {
  border-radius: 8px;
}

@mixin scale-property($property, $min-value, $max-value, $min-width, $max-width, $important: null) {
  // See http://archive.is/fcf6f for details

  // $property = CSS property you want to scale
  // $min-value = minimum font size for range
  // $max-value = maximum font size for range
  // $min-width = lowest resolution in pixels for range
  // $max-width = highest resolution in pixels for range
  // $important = !important string if needed
  // example: @include scale-property(font-size, 46, 53, $ht-desktop-width-raw, $ht-max-width-raw) will evenly scale a font
  // between 43px and 53px between the ranges of 769px and 1920px

  $value-diff: $max-value - $min-value;
  $width-diff: $max-width - $min-width;

  $vw: (100 * $value-diff / $width-diff);
  $px: $min-value - ($min-width * $value-diff / $width-diff);
  $fallback: ($min-value + $max-value) / 2;

  #{$property}: #{$fallback}px #{$important};
  #{$property}: calc(#{$px}px + #{$vw}vw) #{$important};
}

@mixin with-desktop() {
  @media (min-width: $ht-desktop-width) {
    @content;
  }
}

@mixin with-tablet() {
  @media (min-width: $ht-tablet-width) {
    @content;
  }
}

@mixin with-resolution($min) {
  @media (min-width: $min) {
    @content;
  }
}

@mixin override-link-styles() {
  text-decoration: none !important;
  &:visited {
    color: $ht-navy-900 !important;
  }
  &:hover {
    color: $ht-blue-700 !important;
  }
}

@mixin with-hover() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}

@mixin apply-vertical-offset($offset) {
  transform: translateY($offset);
}
