@import 'src/styles/variables';

.htLogo {
  cursor: pointer;
  color: $ht-navy-900;
  font-size: 42px;

  &.defaultCursor {
    cursor: default;
  }
}
